import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import siteInformation from '@content/default.json';

import FacebookIcon from '@icons/socialMedia/facebook.svg';
import TwitterIcon from '@icons/socialMedia/twitter.svg';
import LinkedInIcon from '@icons/socialMedia/linkedin.svg';
import YoutubeIcon from '@icons/socialMedia/youtube.svg';
import GithubIcon from '@icons/socialMedia/github.svg';

import { trackAnalyticsEvent } from '@util/analytics';

import Logo from '@components/Logo';
import Link from '@components/Link';

import * as styles from './Footer.module.css';
import { isExternalHref } from '@util/url';

const Footer = ({ footerMenu }) => {
  const items = footerMenu?.items ?? [];

  const socialLinks = [
    {
      url: siteInformation.socialMedia.urls.facebook,
      icon: FacebookIcon,
      label: 'Cloudsmith on Facebook',
    },
    {
      url: siteInformation.socialMedia.urls.twitter,
      icon: TwitterIcon,
      label: 'Cloudsmith on Twitter',
    },
    {
      url: siteInformation.socialMedia.urls.linkedin,
      icon: LinkedInIcon,
      label: 'Cloudsmith on LinkedIn',
    },
    {
      url: siteInformation.socialMedia.urls.youtube,
      icon: YoutubeIcon,
      label: 'Cloudsmith on YouTube',
    },
    {
      url: siteInformation.socialMedia.urls.github,
      icon: GithubIcon,
      label: 'Cloudsmith on GitHub',
    },
  ];

  return (
    <footer className={styles.root}>
      <nav className={styles.navPrimary}>
        <ul className={styles.navPrimaryList}>
          {items.map((item) => {
            return (
              <li key={item.label} className={cn(styles.navPrimaryListItem)}>
                {item.path ? (
                  <Link
                    prefetch={false}
                    href={item.path}
                    rel={
                      isExternalHref(item.path) ? 'noopener noreferrer' : null
                    }
                    onClick={() => {
                      trackAnalyticsEvent('link', {
                        scope: 'Footer',
                        text: item.label,
                        href: item.path,
                      });
                    }}
                  >
                    {item.label}
                  </Link>
                ) : (
                  <>
                    <span>{item.label}</span>
                    <nav className={cn(styles.navSecondary)}>
                      <ul className={cn(styles.navSecondaryList)}>
                        {item.children.map((child) => (
                          <li
                            key={child.label}
                            className={cn(styles.navSecondaryListItem)}
                          >
                            <Link
                              prefetch={false}
                              href={child.path}
                              target={child.external ? '_blank' : '_self'}
                              rel={
                                isExternalHref(child.path)
                                  ? 'noopener noreferrer'
                                  : null
                              }
                              onClick={() => {
                                trackAnalyticsEvent('link', {
                                  scope: 'Footer',
                                  text: child.label,
                                  href: child.path,
                                });
                              }}
                            >
                              {child.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
      <nav className={styles.bottomFooter}>
        <div className={styles.logoWrapper}>
          <Link prefetch={false} href="/" aria-label="Cloudsmith Homepage">
            <Logo className={cn(styles.logo)} />
          </Link>
        </div>
        <div className={styles.copyRight}>
          &copy; {new Date().getFullYear()} {siteInformation.title}
        </div>
        <div className={styles.socialLinks}>
          {socialLinks.map((link) => (
            <a
              key={link.url}
              href={link.url}
              rel="noopener noreferrer"
              target="_blank"
              className={styles.socialLink}
              aria-label={link.label}
              onClick={() => {
                trackAnalyticsEvent('link', {
                  scope: 'Footer',
                  text: link.label,
                  href: link.url,
                });
              }}
            >
              <link.icon className={styles.socialLinkIcon} />
            </a>
          ))}
        </div>
      </nav>
    </footer>
  );
};

Footer.propTypes = {
  props: PropTypes.object,
};

export default Footer;
